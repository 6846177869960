import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import Select from "react-select";

interface AdminInputFieldProps {
    item: any;
    field: string;
    ddCategory: string;
    updateHandler: any;
    itemUpdateHandler: any;
}

const AdminInputField = (props: AdminInputFieldProps) => {
    // Props
    const { item, field, ddCategory, updateHandler, itemUpdateHandler } = props;
    
    // States
    const [value, setValue] = useState(item[field]);
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [showCheck, setShowCheck] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    const checkIconTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Context
    const dictionaryDataState = useContext(DictionaryDataState) || "";

    /**
     * This function is called after the `apiHandler` on `manager.tsx` has been executed.
     */
    const updateResponseHandler = (response: any) => {
        setShowCheck(true);
        if (checkIconTimeoutRef.current) {
            clearTimeout(checkIconTimeoutRef.current);
        }
        checkIconTimeoutRef.current = setTimeout(() => {
            setShowCheck(false);
        }, 1000);
    }
    
    // Update handler
    const inputUpdateHandler = useCallback(async () => {
        if (!ddCategory) {
            alert('Missing category name.');
            return;
        }

        // Create an updated `item` object
        let updatedItemParams = {

            // Set only the attributes we need for the backend - note that we need `level` instead of `depth`
            id: item.id,
            value: item.id,
            level: item.depth,
            depth: item.depth,
            label: item.label,
            createdAt: item.createdAt,
            parent: item.parent,
            def: item.def,
            status: item.status,
            materialId: item.materialId,

            [field]: debouncedValue,
        }

        // Update `level`
        if( field === 'parent' ){
            updatedItemParams['level'] = item.depth + 1;
            updatedItemParams['depth'] = item.depth + 1;
        }

        // Set `parent` to `DELETE` incase we want to make this a main item
        if( field === 'parent' && debouncedValue === 'DELETE' ){
            updatedItemParams['level'] = 0;
            updatedItemParams['parent'] = 'DELETE';
        }

        // Submit the updated `item` object to the apiHandler in `manager.tsx` through the `updateHandler` hook.
        updateHandler(updatedItemParams, updateResponseHandler);

        // Submit the updated `item` object to the `adminTableRow.tsx` so that other input fields receive the updated `item` object.
        // If we do not do this, updating another input field would overwrite other updates.
        itemUpdateHandler(updatedItemParams);
        
    }, [debouncedValue]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();

        timeoutRef.current = setTimeout(() => {
            setDebouncedValue(value);
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [value]);

    useEffect(() => {
        if (debouncedValue !== item[field]) {
            inputUpdateHandler();
        }
    }, [debouncedValue]);

    // Check the opacity to 0.3 when we made a change, just a small visiual effect
    const selectClasses = showCheck ? "opacity-30" : "";


    // Show `label` instead of `id`
    let selectedDDLabel = '';
    try {
        selectedDDLabel = dictionaryDataState[ddCategory].filter((categoryItem: any) => categoryItem.id === item[field]).map((categoryItem: any) => categoryItem.label).join('');
    } catch (error) {
        console.log("error: ", error);
    }

    const staticDisplay = field === 'parent' ? selectedDDLabel : item[field] ;

    return item.status === "NEW" ? (
        <div className="relative">
            {field === 'parent' ? (
                <Select
                    key={item.id + field}
                    name={field}
                    options={[
                        { label: '-- Main Item --', value: 'DELETE' },
                        ...dictionaryDataState[ddCategory].map((categoryItem: any) => {

                            const indent = "\xa0".repeat(categoryItem.depth * 4); // <<< this is the indent (how many &nbsp; )

                            return {
                                label: `${indent}${categoryItem.label}`,
                                value: categoryItem.id,
                            };
                        })
                    ]}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: `200px`,
                        }),
                    }}
                    className={selectClasses}
                    onChange={(selectedOption) => {
                        console.log("🚀 ~ AdminInputField ~ selectedOption:", selectedOption);
                        setValue(selectedOption.value);
                    }}
                    value={dictionaryDataState[ddCategory].filter((categoryItem: any) => categoryItem.id === item.parent)}
                />
            ) : (
                <input
                    type="text"
                    key={item.id + field}
                    name={field}
                    value={value} 
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    className="w-full css-1s2u09g-control css-13cymwt-control px-2"
                />
            )}
            
            {showCheck && (
                <span className="absolute right-2 top-2" style={{ color: `rgb(9 215 9)`}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
                        <path d="M9 16.172l-3.586-3.586-1.414 1.414 5 5 12-12-1.414-1.414z"/>
                    </svg>
                </span>
            )}
        </div>
    ) : (
        <>
            {staticDisplay}
        </>
    );
}


export default AdminInputField;